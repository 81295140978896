import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Logo = props => (
  <StaticQuery
    query={graphql`
      query {
        Logo: file(relativePath: {
          eq: "reach-white.png"
        }) {
          childImageSharp {
            fluid(maxWidth: 200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              presentationWidth
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        {...props}
        loading="eager"
        fadeIn={false}
        fluid={data.Logo.childImageSharp.fluid}
      />
    )}
  />
);
export default Logo;